@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Artesania';
  src: url('/public/assets/fonts/artesania.otf');
}

.brand-font {
  font-family: 'Artesania';
}

header .active {
  border-bottom: .2em rgb(12, 213, 200) solid;
}